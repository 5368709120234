<template>
  <div class="row">
    <div class="col-md-12">
      <div class="nav-tabs-custom">
        <ul class="nav nav-tabs">
          <li class="active"><a href="#profile" data-toggle="tab">{{ $t('string.profile') }}</a></li>
          <li><a href="#resetPassword" data-toggle="tab">{{ $t('string.resetPassword') }}</a></li>
          <li><a href="#changeAvatar" data-toggle="tab">{{ $t('string.changeAvatar') }}</a></li>
        </ul>
        <div class="tab-content">
          <div class="active tab-pane" id="profile">
            <profile/>
          </div>
          <div class="tab-pane" id="resetPassword">
            <reset-password/>
          </div>
          <div class="tab-pane" id="changeAvatar">
            <change-avatar/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ResetPassword from '@/views/admin/pages/account/includes/ResetPassword'
import ChangeAvatar from '@/views/admin/pages/account/includes/ChangeAvatar'
import Profile from '@/views/admin/pages/account/includes/Profile'

export default {
  name: 'Account',
  components: {
    Profile,
    ChangeAvatar,
    ResetPassword
  },
  metaInfo () {
    return {
      title: this.$t('string.profile'),
      titleTemplate: '%s | ' + process.env.VUE_APP_NAME
    }
  }
}
</script>

<style scoped>
.tab-content {
  border: none !important;
}
</style>
