<template>
  <div class="table-responsive" v-if="user">
    <div class="mb-20 clearfix">
      <template v-if="isUpdating">
        <button @click="switchUpdateMode(false)" class="btn btn-danger pull-right">
          <i class="fa fa-times-circle"></i> {{ $t('button.cancel') }}
        </button>
        <button @click="updateProfile" class="btn btn-success pull-right mr-5">
          <i class="fa fa-save"></i> {{ $t('button.save') }}
        </button>
      </template>
      <template v-else>
        <button @click="switchUpdateMode(true)" class="btn btn-primary pull-right">
          <i class="fa fa-edit"></i> {{ $t('button.update') }}
        </button>
      </template>
    </div>
    <table class="table table-striped table-hover">
      <tbody>
      <tr>
        <th>{{ $t('fields.firstName') }}</th>
        <template v-if="isUpdating">
          <td>
            <div class="form-group no-margin"
                 :class="{'has-error': validations.hasOwnProperty('first_name')}">
              <input type="text"
                     v-model="userForm.first_name"
                     class="form-control"
                     :placeholder="$t('fields.firstName')"/>
              <template v-if="validations.hasOwnProperty('first_name')">
                  <span class="help-block">
                    {{ validations['first_name'][0] }}
                  </span>
              </template>
            </div>
          </td>
        </template>
        <template v-else>
          <td>{{ user.first_name }}</td>
        </template>
      </tr>
      <tr>
        <th>{{ $t('fields.lastName') }}</th>
        <template v-if="isUpdating">
          <td>
            <div class="form-group no-margin" :class="{ 'has-error': validations.hasOwnProperty('last_name') }">
              <input type="text"
                     v-model="userForm.last_name"
                     class="form-control"
                     :placeholder="$t('fields.lastName')"/>
              <template v-if="validations.hasOwnProperty('last_name')">
                <span class="help-block">
                    {{ validations['last_name'][0] }}
                </span>
              </template>
            </div>
          </td>
        </template>
        <template v-else>
          <td>{{ user.last_name }}</td>
        </template>
      </tr>
      <tr>
        <th>{{ $t('fields.email') }}</th>
        <template v-if="isUpdating">
          <td>
            <div class="form-group no-margin" :class="{ 'has-error': validations.hasOwnProperty('email') }">
              <input type="email"
                     v-model="userForm.email"
                     readonly
                     class="form-control"
                     :placeholder="$t('fields.email')"/>
              <template v-if="validations.hasOwnProperty('email')">
                <span class="help-block">
                    {{ validations['email'][0] }}
                </span>
              </template>
            </div>
          </td>
        </template>
        <template v-else>
          <td>{{ user.email }}</td>
        </template>
      </tr>
      <tr>
        <th>{{ $t('fields.gender') }}</th>
        <template v-if="isUpdating">
          <td>
            <div class="form-group no-margin" :class="{ 'has-error': validations.hasOwnProperty('gender_id') }">
              <select class="form-control" v-model="userForm.gender_id">
                <option selected disabled value="0">{{ $t('string.selectGender') }}</option>
                <option value="1">{{ $t('label.male') }}</option>
                <option value="2">{{ $t('label.female') }}</option>
              </select>
              <template v-if="validations.hasOwnProperty('gender_id')">
                <span class="help-block">
                    {{ validations['gender_id'][0] }}
                </span>
              </template>
            </div>
          </td>
        </template>
        <template v-else>
          <td>
            {{ user.gender ? user.gender['name_' + $i18n.locale] : 'N/A' }}
          </td>
        </template>
      </tr>
      <template v-if="!isUpdating">
        <tr>
          <th>{{ $t('fields.createdAt') }}</th>
          <td>{{ user.created_at }}</td>
        </tr>
        <tr>
          <th>{{ $t('fields.updatedAt') }}</th>
          <td>{{ user.updated_at }}</td>
        </tr>
      </template>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Profile',
  computed: {
    ...mapState({
      user: state => state.user.data
    })
  },
  data () {
    return {
      isUpdating: false,
      userForm: {},
      validations: {}
    }
  },
  methods: {
    switchUpdateMode (isUpdating) {
      if (isUpdating) {
        this.userForm = {
          first_name: this.user.first_name,
          last_name: this.user.last_name,
          // email: this.user.email,
          gender_id: this.userForm.gender_id
        }
        this.validations = {}
      }
      this.isUpdating = isUpdating
    },
    updateProfile () {
      this.validations = {}
      this.$isLoading(true)
      this.$axios.post(process.env.VUE_APP_API + '/api/auth/backend/update-information', this.userForm)
        .then((response) => {
          if (response.data && response.data.data) {
            const user = response.data.data
            this.$store.dispatch('user/setUser', { user: user })
            this.userForm = {
              first_name: user.first_name,
              last_name: user.last_name,
              // email: user.email,
              gender_id: user.gender_id,
              created_at: user.created_at,
              updated_at: user.updated_at
            }
            this.switchUpdateMode(false)
            this.$toastr('success', this.$t('string.profileUpdate'), this.$t('string.success'))
          }
        })
        .catch((error) => {
          if (error.response.status === 422) {
            this.validations = error.response.data.errors
          } else {
            this.onResponseError(error)
          }
        })
        .finally(() => {
          this.$isLoading(false)
        })
    }
  }
}
</script>

<style scoped>
.mb-20 {
  margin-bottom: 20px;
}

.mr-5 {
  margin-right: 5px;
}

.no-margin {
  margin-bottom: 0 !important;
}
</style>
