<template>
  <div class="change-avatar">
    <p class="text-center" style="margin-bottom: 10px;">
      <img :src="imgDataUrl || '/images/avatar/avatar.png'" width="300" height="300"/>
    </p>
    <a class="btn btn-primary"
       @click="toggleShow">{{ $t('button.uploadYourAvatar') }}</a>
    <my-upload field="avatar"
               @crop-success="cropSuccess"
               @crop-upload-success="cropUploadSuccess"
               @crop-upload-fail="cropUploadFail"
               v-model="show"
               :width="300"
               lang-type="en"
               :height="300"
               :url="`${apiUrl}/api/auth/backend/change-avatar`"
               :params="params"
               :headers="headers"
               img-format="png"/>
  </div>
</template>

<script>
import 'babel-polyfill'
import myUpload from 'vue-image-crop-upload'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'ChangeAvatar',
  components: {
    'my-upload': myUpload
  },
  data () {
    return {
      apiUrl: process.env.VUE_APP_API,
      show: false,
      params: {
        user_id: null,
        name: 'avatar'
      },
      headers: null,
      imgDataUrl: ''
    }
  },
  computed: {
    ...mapState({
      user: state => state.user.data
    })
  },
  methods: {
    ...mapActions([
      'setUser'
    ]),
    toggleShow () {
      this.show = !this.show
    },
    cropSuccess (imgDataUrl, field) {
    },
    cropUploadSuccess (jsonData, field) {
      this.$store.dispatch('user/setUser', { user: jsonData.data })
      this.imgDataUrl = this.apiUrl + '/' + jsonData.data.avatar
    },
    cropUploadFail (status, field) {
    }
  },
  mounted () {
    this.headers = {
      Authorization: 'Bearer ' + localStorage.getItem(this.$token),
      Accept: 'application/json'
    }
    this.imgDataUrl = this.user.avatar ? this.apiUrl + '/' + this.user.avatar : this.user.avatar
  }
}
</script>

<style scoped>
.change-avatar {
  align-content: center;
  text-align: center;
  padding-top: 10vh;
  padding-bottom: 10vh;
}
</style>
